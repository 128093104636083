import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/skeleton/Skeleton.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/skeleton" storybook="https://workday.github.io/canvas-kit/?path=/story/components-indicators-skeleton--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=30877-34301&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/988247635933ac5342eb87eda8bc0f6c/536c7/skeleton-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "32.43243243243243%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Loading Animation with annotation markers.",
              "title": "Image of a Loading Animation with annotation markers.",
              "src": "/static/988247635933ac5342eb87eda8bc0f6c/50383/skeleton-anatomy.png",
              "srcSet": ["/static/988247635933ac5342eb87eda8bc0f6c/1efb2/skeleton-anatomy.png 370w", "/static/988247635933ac5342eb87eda8bc0f6c/50383/skeleton-anatomy.png 740w", "/static/988247635933ac5342eb87eda8bc0f6c/536c7/skeleton-anatomy.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Shape Skeleton`}</strong>{`: Represents graphical elements such as avatars, icons, and small Images.`}</li>
        <li parentName="ol"><strong parentName="li">{`Header Skeleton`}</strong>{`: Represent headings and sub-headings.`}</li>
        <li parentName="ol"><strong parentName="li">{`Text Skeleton`}</strong>{`: Represent paragraph and body text.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`A Skeleton should provide a close representation of the ultimate page layout and content once
loaded.`}</li>
        <li parentName="ul">{`Use motion within the Skeleton to reinforce that the page is loading behind the scenes.`}</li>
        <li parentName="ul">{`Ideal for pages that require longer initial load times.`}</li>
        <li parentName="ul">{`Use for pages where all content loads at the same time.`}</li>
        <li parentName="ul">{`Meant to be used specifically on a white background.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Skeleton if the visual layout/format of the content being loaded is known ahead of time.`}</li>
        <li parentName="ul">{`Use specifically on pages where all or a majority of the page will be taking time to load.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`If the visual layout/format of the content being loaded is unknown; or you need to indicate
processing or that change will occur on the page (rather than loading UI elements), consider using
a `}<a parentName="li" {...{
            "href": "/components/indicators/loading-dots"
          }}>{`Loading Dots`}</a>{` instead.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`A Skeleton should announce to users using a screen reader that content or a page is being loaded.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Though the Skeleton component does not use actual content, the Header Skeleton and the Text
Skeleton elements should be used as though they are actual content.`}</li>
        <li parentName="ul">{`The Header Skeleton will represent headings and sub-headings while the Text Skeleton will
represent paragraph or body text.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7739%3A5927&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/536c7/component-anatomy-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Card Skeleton with annotation markers.",
                "title": "Image of a Card Skeleton with annotation markers.",
                "src": "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/50383/component-anatomy-skeleton-mobile.png",
                "srcSet": ["/static/9d1a4c4f38f42f08206fe7a5bbba29a5/1efb2/component-anatomy-skeleton-mobile.png 370w", "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/50383/component-anatomy-skeleton-mobile.png 740w", "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/536c7/component-anatomy-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Shape Skeleton`}</strong>{`: Represents graphical elements such as avatars, icons, and small images.`}</li>
          <li parentName="ol"><strong parentName="li">{`Header Skeleton`}</strong>{`: Represent headings and subheadings.`}</li>
          <li parentName="ol"><strong parentName="li">{`Text Skeleton:`}</strong>{` Represent paragraph and body text.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Tailor Skeleton to provide an approximate representation of the final page layout and content that
will be loaded. If the skeleton presented does not closely match the final content loaded,
consider using a different loading animation instead.`}</li>
          <li parentName="ul">{`Use a shimmer animation within the entire Skeleton to reinforce the notion that the page is
loading behind the scenes.`}</li>
          <li parentName="ul">{`Use Skeleton for pages that require longer initial load times, over 300ms.`}</li>
          <li parentName="ul">{`Use Skeleton for pages where all content will load at the same time.`}</li>
          <li parentName="ul">{`Skeleton is designed specifically to be used against a white background.`}</li>
          <li parentName="ul">{`If content fails to load, use a contextual Empty State that clearly communicates why there is no
data, and explains any next steps the user should take.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Skeleton if the visual layout/format of the overall content being loaded is known ahead of
time.`}</li>
          <li parentName="ul">{`Use on pages where all or a majority of the page will take time to load.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If the visual layout/format of the content being loaded is unknown; or you need to indicate
processing or that change will occur on the page (rather than loading UI elements), consider using
a Loading Animtation instead.`}</li>
          <li parentName="ul">{`If load times are insignificant (under 100ms), consider not using a loading treatment at all as it
will not help perceived performance.`}</li>
        </ul>
        <h2 {...{
          "id": "variations"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "circle"
        }}>{`Circle`}</h3>
        <p>{`Use Circle Skeleton for Avatars and Icons.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/536c7/component-variation-circle-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of an Applet Icon.",
                "title": "Before and after loading state of an Applet Icon.",
                "src": "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/50383/component-variation-circle-skeleton-mobile.png",
                "srcSet": ["/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/1efb2/component-variation-circle-skeleton-mobile.png 370w", "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/50383/component-variation-circle-skeleton-mobile.png 740w", "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/536c7/component-variation-circle-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "custom"
        }}>{`Custom`}</h3>
        <p>{`Custom Skeleton is used to represent small images before they are loaded onto the screen.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/69dd6f0df6bd52487f8629fb96ace314/536c7/component-variation-custom-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of an Image.",
                "title": "Before and after loading state of an Image.",
                "src": "/static/69dd6f0df6bd52487f8629fb96ace314/50383/component-variation-custom-skeleton-mobile.png",
                "srcSet": ["/static/69dd6f0df6bd52487f8629fb96ace314/1efb2/component-variation-custom-skeleton-mobile.png 370w", "/static/69dd6f0df6bd52487f8629fb96ace314/50383/component-variation-custom-skeleton-mobile.png 740w", "/static/69dd6f0df6bd52487f8629fb96ace314/536c7/component-variation-custom-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "header"
        }}>{`Header`}</h3>
        <p>{`Header Skeleton is used as a loading placeholder to cover all heading text styles: H1, H2, and H3.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bfd64c65aea2f63c76128281931d61ea/536c7/component-variation-header-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of H1, H2, and H3 elements",
                "title": "Before and after loading state of H1, H2, and H3 elements",
                "src": "/static/bfd64c65aea2f63c76128281931d61ea/50383/component-variation-header-skeleton-mobile.png",
                "srcSet": ["/static/bfd64c65aea2f63c76128281931d61ea/1efb2/component-variation-header-skeleton-mobile.png 370w", "/static/bfd64c65aea2f63c76128281931d61ea/50383/component-variation-header-skeleton-mobile.png 740w", "/static/bfd64c65aea2f63c76128281931d61ea/536c7/component-variation-header-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "text"
        }}>{`Text`}</h3>
        <p>{`Text Skeleton is used as a loading placeholder to cover all body and subtext text styles.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/46f3733f7c30d8f59ba5128af72aac61/536c7/component-variation-body-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of text styles",
                "title": "Before and after loading state of text styles",
                "src": "/static/46f3733f7c30d8f59ba5128af72aac61/50383/component-variation-body-skeleton-mobile.png",
                "srcSet": ["/static/46f3733f7c30d8f59ba5128af72aac61/1efb2/component-variation-body-skeleton-mobile.png 370w", "/static/46f3733f7c30d8f59ba5128af72aac61/50383/component-variation-body-skeleton-mobile.png 740w", "/static/46f3733f7c30d8f59ba5128af72aac61/536c7/component-variation-body-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct SkeletonLoader: View
`}</code></pre>
        <p>{`WD-Standard SkeletonLoader component, a custom SwiftUI view. The SkeletonLoader view represents the
loading state of an incoming view`}</p>
        <h3 {...{
          "id": "properties"
        }}>{`Properties`}</h3>
        <p><inlineCode parentName="p">{`body`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public var body: some View
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><inlineCode parentName="p">{`init(_:doAnimate:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(_ components: [SkeletonLoaderComponent] = [
    .avatar,
    .shorterText,
    .text,
    .divider
], doAnimate: Bool = true)
`}</code></pre>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`components`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of SkeletonLoaderComponents used to form a skeleton view. These components will be positioned in the view container based on their type (i.e. .avatar is anchored to the left, .divider is anchored to the bottom, .icons are stacked horizontally, and text components are stacked vertically).`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Screen Readers announce that content or a page is being loaded.`}</li>
          <li parentName="ul">{`When a new page is displayed, be sure the user’s focus begins at the top of the screen again.`}</li>
          <li parentName="ul">{`When used for a section (or sections) of the page, screen readers should announce the section is
still loading, and politely alert users when loading is finished.`}</li>
        </ul>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Though the Skeleton component does not use actual content, the Header Skeleton and the Text
Skeleton should be used as if they were actual content.`}</li>
          <li parentName="ul">{`The Header Skeleton represents headings and subheadings while the Text Skeleton represents
paragraph or body text.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7739%3A5927&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/536c7/component-anatomy-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Card Skeleton with annotation markers.",
                "title": "Image of a Card Skeleton with annotation markers.",
                "src": "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/50383/component-anatomy-skeleton-mobile.png",
                "srcSet": ["/static/9d1a4c4f38f42f08206fe7a5bbba29a5/1efb2/component-anatomy-skeleton-mobile.png 370w", "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/50383/component-anatomy-skeleton-mobile.png 740w", "/static/9d1a4c4f38f42f08206fe7a5bbba29a5/536c7/component-anatomy-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Shape Skeleton`}</strong>{`: Represents graphical elements such as avatars, icons, and small images.`}</li>
          <li parentName="ol"><strong parentName="li">{`Header Skeleton`}</strong>{`: Represent headings and subheadings.`}</li>
          <li parentName="ol"><strong parentName="li">{`Text Skeleton:`}</strong>{` Represent paragraph and body text.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Tailor Skeleton to provide an approximate representation of the final page layout and content that
will be loaded. If the skeleton presented does not closely match the final content loaded,
consider using a different loading animation instead.`}</li>
          <li parentName="ul">{`Use a shimmer animation within the entire Skeleton to reinforce the notion that the page is
loading behind the scenes.`}</li>
          <li parentName="ul">{`Use Skeleton for pages that require longer initial load times, over 300ms.`}</li>
          <li parentName="ul">{`Use Skeleton for pages where all content will load at the same time.`}</li>
          <li parentName="ul">{`Skeleton is designed specifically to be used against a white background.`}</li>
          <li parentName="ul">{`If content fails to load, use a contextual Empty State that clearly communicates why there is no
data, and explains any next steps the user should take.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Skeleton if the visual layout/format of the overall content being loaded is known ahead of
time.`}</li>
          <li parentName="ul">{`Use on pages where all or a majority of the page will take time to load.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If the visual layout/format of the content being loaded is unknown; or you need to indicate
processing or that change will occur on the page (rather than loading UI elements), consider using
a loading animation instead.`}</li>
          <li parentName="ul">{`If load times are insignificant (under 100ms), consider not using a loading treatment at all as it
will not help perceived performance.`}</li>
        </ul>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "circle-1"
        }}>{`Circle`}</h3>
        <p>{`Use Circle Skeleton for Avatars and Icons.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/536c7/component-variation-circle-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of an Applet Icon",
                "title": "Before and after loading state of an Applet Icon",
                "src": "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/50383/component-variation-circle-skeleton-mobile.png",
                "srcSet": ["/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/1efb2/component-variation-circle-skeleton-mobile.png 370w", "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/50383/component-variation-circle-skeleton-mobile.png 740w", "/static/fd381137b4eedaa4cdbc0a62ad8d1fa3/536c7/component-variation-circle-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "custom-1"
        }}>{`Custom`}</h3>
        <p>{`Custom Skeleton is used to represent small images before they are loaded onto the screen.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/69dd6f0df6bd52487f8629fb96ace314/536c7/component-variation-custom-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of an Image",
                "title": "Before and after loading state of an Image",
                "src": "/static/69dd6f0df6bd52487f8629fb96ace314/50383/component-variation-custom-skeleton-mobile.png",
                "srcSet": ["/static/69dd6f0df6bd52487f8629fb96ace314/1efb2/component-variation-custom-skeleton-mobile.png 370w", "/static/69dd6f0df6bd52487f8629fb96ace314/50383/component-variation-custom-skeleton-mobile.png 740w", "/static/69dd6f0df6bd52487f8629fb96ace314/536c7/component-variation-custom-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "header-1"
        }}>{`Header`}</h3>
        <p>{`Header Skeleton is used as a loading placeholder to cover all heading text styles: H1, H2, and H3.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bfd64c65aea2f63c76128281931d61ea/536c7/component-variation-header-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before and after loading state of H1, H2, and H3 elements",
                "title": "Before and after loading state of H1, H2, and H3 elements",
                "src": "/static/bfd64c65aea2f63c76128281931d61ea/50383/component-variation-header-skeleton-mobile.png",
                "srcSet": ["/static/bfd64c65aea2f63c76128281931d61ea/1efb2/component-variation-header-skeleton-mobile.png 370w", "/static/bfd64c65aea2f63c76128281931d61ea/50383/component-variation-header-skeleton-mobile.png 740w", "/static/bfd64c65aea2f63c76128281931d61ea/536c7/component-variation-header-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "text-1"
        }}>{`Text`}</h3>
        <p>{`Text Skeleton is used as a loading placeholder to cover all body and subtext text styles.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/46f3733f7c30d8f59ba5128af72aac61/536c7/component-variation-body-skeleton-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Placeholder alt text",
                "title": "Placeholder alt text",
                "src": "/static/46f3733f7c30d8f59ba5128af72aac61/50383/component-variation-body-skeleton-mobile.png",
                "srcSet": ["/static/46f3733f7c30d8f59ba5128af72aac61/1efb2/component-variation-body-skeleton-mobile.png 370w", "/static/46f3733f7c30d8f59ba5128af72aac61/50383/component-variation-body-skeleton-mobile.png 740w", "/static/46f3733f7c30d8f59ba5128af72aac61/536c7/component-variation-body-skeleton-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`fun SkeletonLoadingUiComponent(
  components: List; SkeletonComponent; = DEFAULT_SET_COMPONENTS_WITH_DIVIDER,
  doAnimate: Boolean = true
)
`}</code></pre>
        <h3 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`components`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A list of SkeletonComponents that will be displayed in a row. The available SkeletonComponents are: Avatar, Icon, Header, Text, Shorter Text, Divider, and Custom Shape`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`doAnimate`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Boolean that controls whether the skeleton loader will display the shimmer animation`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Screen Readers announce that content or a page is being loaded.`}</li>
          <li parentName="ul">{`When a new page is displayed, be sure the user’s focus begins at the top of the screen again.`}</li>
          <li parentName="ul">{`When used for a section (or sections) of the page, screen readers should announce the section is
still loading, and politely alert users when loading is finished.`}</li>
        </ul>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Though the Skeleton component does not use actual content, the Header Skeleton and the Text
Skeleton should be used as if they were actual content.`}</li>
          <li parentName="ul">{`The Header Skeleton represents headings and subheadings while the Text Skeleton represents
paragraph or body text.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      